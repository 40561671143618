<template>
  <div>
    <b-modal
      id="addVehicleModal"
      :title="$t('conf.vehicle.popup.title')"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      no-close-on-backdrop
      @hidden="$emit('close')"
      @ok="submitForm"
    >

      <validation-observer ref="newVehicleValidation">
        <form
          id="createVehicleForm"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.vehicle.brand')"
                rules="required|min:3|max:50"
              >
                <label for="brand">
                  {{ $t('conf.vehicle.brand') }}
                </label>
                <b-form-input
                  id="brand"
                  v-model="newVehicleForm.brand"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.vehicle.brand')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.vehicle.model')"
                rules="required|min:3|max:50"
              >
                <label for="model">
                  {{ $t('conf.vehicle.model') }}
                </label>
                <b-form-input
                  id="model"
                  v-model="newVehicleForm.model"
                  type="text"
                  minlength="3"
                  maxlength="50"
                  :placeholder="$t('conf.vehicle.model')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.vehicle.registration-number')"
                rules="required|alpha-num|min:3|max:10"
              >
                <label for="registrationNumber">
                  {{ $t('conf.vehicle.registration-number') }}
                </label>
                <b-form-input
                  id="registrationNumber"
                  v-model="newVehicleForm.registrationNumber"
                  type="text"
                  minlength="3"
                  maxlength="10"
                  :placeholder="$t('conf.vehicle.registration-number')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.vehicle.side-number')"
                rules="required|alpha-num|min:3|max:10"
              >
                <label for="sideNumber">
                  {{ $t('conf.vehicle.side-number') }}
                </label>
                <b-form-input
                  id="sideNumber"
                  v-model="newVehicleForm.sideNumber"
                  type="text"
                  minlength="3"
                  maxlength="10"
                  :placeholder="$t('conf.vehicle.side-number')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.vehicle.gps-identifier')"
                rules=""
              >
                <b-form-group>
                  <label for="vehicleTrackingIdentifier">
                    {{ $t('conf.vehicle.gps-identifier') }}
                  </label>
                  <v-select
                    id="vehicleTrackingIdentifier"
                    v-model="newVehicleForm.gpsIdentifier"
                    :placeholder="$t('conf.vehicle.gps-identifier')"
                    :options="trackingIdentifiers"
                    :reduce="gpsIdentifier => gpsIdentifier.externalIdentifier"
                  >
                    <span
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ option.description }} ({{ option.externalIdentifier }})
                    </span>
                    <template #option="{ externalIdentifier, description}">
                      <span>{{ description }} ({{ externalIdentifier }})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

              </validation-provider>

            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('conf.vehicle.status')"
                rules="required"
              >
                <b-form-group>
                  <label for="vehicleStatus">
                    {{ $t('conf.vehicle.status') }}
                  </label>
                  <v-select
                    id="vehicleStatus"
                    v-model="newVehicleForm.status"
                    :placeholder="$t('conf.vehicle.status')"
                    :options="statuses"
                    :clearable="false"
                    :searchable="false"
                  >
                    <span
                      slot="selected-option"
                      slot-scope="option"
                    >
                      {{ $t('conf.vehicle.statuses.' + option.label) }}
                    </span>
                    <template #option="{ label }">
                      <span>{{ $t('conf.vehicle.statuses.' + label) }}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

              </validation-provider>

            </b-col>

          </b-row>
        </form>

      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import {
  BCol, BFormGroup, BFormInput, BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required, alphaNum, digits } from '@validations'

export default {
  components: {
    BModal,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      digits,
      trackingIdentifiers: [],
      newVehicleForm: this.$store.state.vehicle.emptyVehicle,
    }
  },
  computed: {
    statuses() {
      return cloneNested(this.$store.getters['vehicle/getVehicleStatuses'])
    },
  },
  mounted() {
    this.$bvModal.show('addVehicleModal')
    this.initFormData()
  },
  methods: {
    submitForm(event) {
      event.preventDefault()
      this.$refs.newVehicleValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.$store.dispatch('vehicle/addVehicle', this.newVehicleForm)
            .then(() => {
              this.$bvModal.hide('addVehicleModal')
              this.$emit('submitted')
              window.toast.notify.success(this.$t('conf.vehicle.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
    initFormData() {
      Promise.all([
        this.$store.dispatch('vehicle/fetchVehicleStatuses'),
        this.$store.dispatch('vehicle/fetchTrackingIdentifiers'),
      ])
        .then(res => {
          this.trackingIdentifiers = cloneNested(res[1])
        })
        .catch(err => parseRequestError(err))
    },
  },
}
</script>
