var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"addVehicleModal","title":_vm.$t('conf.vehicle.popup.title'),"ok-title":_vm.$t('shared.button.submit'),"cancel-title":_vm.$t('shared.button.cancel'),"no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('close')},"ok":_vm.submitForm}},[_c('validation-observer',{ref:"newVehicleValidation"},[_c('form',{attrs:{"id":"createVehicleForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.vehicle.brand'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"brand"}},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.brand'))+" ")]),_c('b-form-input',{attrs:{"id":"brand","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.vehicle.brand'),"autocomplete":"off"},model:{value:(_vm.newVehicleForm.brand),callback:function ($$v) {_vm.$set(_vm.newVehicleForm, "brand", $$v)},expression:"newVehicleForm.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.vehicle.model'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"model"}},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.model'))+" ")]),_c('b-form-input',{attrs:{"id":"model","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('conf.vehicle.model'),"autocomplete":"off"},model:{value:(_vm.newVehicleForm.model),callback:function ($$v) {_vm.$set(_vm.newVehicleForm, "model", $$v)},expression:"newVehicleForm.model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.vehicle.registration-number'),"rules":"required|alpha-num|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"registrationNumber"}},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.registration-number'))+" ")]),_c('b-form-input',{attrs:{"id":"registrationNumber","type":"text","minlength":"3","maxlength":"10","placeholder":_vm.$t('conf.vehicle.registration-number'),"autocomplete":"off"},model:{value:(_vm.newVehicleForm.registrationNumber),callback:function ($$v) {_vm.$set(_vm.newVehicleForm, "registrationNumber", $$v)},expression:"newVehicleForm.registrationNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.vehicle.side-number'),"rules":"required|alpha-num|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"sideNumber"}},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.side-number'))+" ")]),_c('b-form-input',{attrs:{"id":"sideNumber","type":"text","minlength":"3","maxlength":"10","placeholder":_vm.$t('conf.vehicle.side-number'),"autocomplete":"off"},model:{value:(_vm.newVehicleForm.sideNumber),callback:function ($$v) {_vm.$set(_vm.newVehicleForm, "sideNumber", $$v)},expression:"newVehicleForm.sideNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.vehicle.gps-identifier'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"vehicleTrackingIdentifier"}},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.gps-identifier'))+" ")]),_c('v-select',{attrs:{"id":"vehicleTrackingIdentifier","placeholder":_vm.$t('conf.vehicle.gps-identifier'),"options":_vm.trackingIdentifiers,"reduce":function (gpsIdentifier) { return gpsIdentifier.externalIdentifier; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(option.description)+" ("+_vm._s(option.externalIdentifier)+") ")])}},{key:"option",fn:function(ref){
var externalIdentifier = ref.externalIdentifier;
var description = ref.description;
return [_c('span',[_vm._v(_vm._s(description)+" ("+_vm._s(externalIdentifier)+")")])]}}],null,true),model:{value:(_vm.newVehicleForm.gpsIdentifier),callback:function ($$v) {_vm.$set(_vm.newVehicleForm, "gpsIdentifier", $$v)},expression:"newVehicleForm.gpsIdentifier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('conf.vehicle.status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"vehicleStatus"}},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.status'))+" ")]),_c('v-select',{attrs:{"id":"vehicleStatus","placeholder":_vm.$t('conf.vehicle.status'),"options":_vm.statuses,"clearable":false,"searchable":false},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return _c('span',{},[_vm._v(" "+_vm._s(_vm.$t('conf.vehicle.statuses.' + option.label))+" ")])}},{key:"option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(_vm._s(_vm.$t('conf.vehicle.statuses.' + label)))])]}}],null,true),model:{value:(_vm.newVehicleForm.status),callback:function ($$v) {_vm.$set(_vm.newVehicleForm, "status", $$v)},expression:"newVehicleForm.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }